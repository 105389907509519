import { SyntheticEvent, useState, ReactNode, useEffect } from 'react';
import { Box, Typography, Tab, Tabs, Grid } from '@mui/material';
import { TabSystemPrice } from '@/shared/constant/common';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import TitlePage from '@/components/TitlePage';
import CentralAndNorthPrice from './CentralAndNorthPrice';
import SouthPrice from './SouthPrice';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
  };
}

export default function TabSystemPriceComponents() {
  const location = useLocation();
  const currentIndex = TabSystemPrice.findIndex((tab) => tab.url.includes(location.pathname));
  const tabData = [{ component: <CentralAndNorthPrice /> }, { component: <SouthPrice /> }];

  const [value, setValue] = useState<number>(currentIndex !== -1 ? currentIndex : 0);

  useEffect(() => {
    if (currentIndex !== -1 && currentIndex !== value) {
      setValue(currentIndex);
    }
  }, [currentIndex]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        {TabSystemPrice.map((tab, index) => (
          <Tab key={index} label={tab.label} component={Link} to={tab.url} />
        ))}
      </Tabs>

      {tabData.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
